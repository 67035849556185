<script lang="ts">
  import { formatValue } from "$lib/common/helpers/formatValue";
  import { getOrdinal } from "$lib/common/helpers/ordinalFormat";
  import { translations } from "$lib/data/translations";
  import Button from "$lib/main/components/Button.svelte";
  import Icons from "$lib/main/components/Icons.svelte";
  import Translate from "../../helpers/Translate.svelte";
  import OperatorItem from "$lib/admin/components/blocks/helpers/OperatorItem.svelte";
  import Modal from "$lib/main/components/Modal.svelte";
  import type { ExperienceValues } from "$lib/common/enums/experience";
  import { getBucketLink } from "$lib/common/helpers/image";
  import { translate } from "$lib/common/helpers/translate";

  let title: string;
  export let toggleModal: boolean;
  export let fullWidth: boolean;
  export let modalData: any;
  export let selectedType: ExperienceValues;
  export let selectedPaymentMethod: string;
  export let searchText: string;
  export let filteredList: any;
  export let timeMapper: { [key in ExperienceValues]: string };
  export let lang: string;

  interface Translations {
    [key: string]: { [key: string]: string };
  }

  export let translationsUtil: Translations;

  function filterList() {
    if (!searchText) {
      filteredList = modalData.rankingList;
      return;
    }

    filteredList = modalData.rankingList.filter((item: any) =>
      item.name.toLowerCase().includes(searchText.toLowerCase()),
    );
  }
</script>

<Modal bind:toggleModal bind:fullWidth removeHeader={true}>
  <div class="container operator-modal">
    <div class="head">
      <Button variant="text" type="button" addClass="text-btn" on:click={() => (toggleModal = false)}>
        <Icons name="arrow-left" strokeWidth="2"></Icons>
      </Button>
      <h3>
        {translationsUtil[selectedType]?.[lang]}{selectedPaymentMethod ? " - " + selectedPaymentMethod : ""}
      </h3>
      <div class="empty"></div>
    </div>
    <div
      class="selected-operator-modal"
      style={modalData.rankDetails.visuals?.primaryColor === "255,255,255,1"
        ? ""
        : `background-color: rgba(${modalData.rankDetails.visuals?.primaryColor || "0,0,0,1"}); color: rgba(${modalData.rankDetails.visuals?.textColor || "255,255,255,1"})`}>
      <div class="logo">
        <img alt={modalData.rankDetails.name} src="{getBucketLink()}operators/{modalData.rankDetails.visuals.logo}" />
        <div style="width: 100%; text-align: center; font-size: 18px; margin-top: 10px;">
          #{modalData.rankDetails.rank}
        </div>
      </div>
      <div class="rank-info">
        {#if modalData.totalRoundCount !== undefined && modalData.totalRoundCount !== null}
          <div
            class="rank-box"
            style={modalData.rankDetails.visuals?.primaryColor === "255,255,255,1"
              ? "background-color: rgba(0,0,0,0.1);"
              : ""}>
            <Icons
              stroke="rgba({modalData.rankDetails.visuals.textColor})"
              strokeWidth="0"
              fill="rgba({modalData.rankDetails.visuals.textColor})"
              name="total-operators"
              width="32"
              height="32" />
            <span
              ><Translate text="amount tests" />
              {selectedPaymentMethod || ""}:</span>
            <span>{modalData.totalRoundCount}</span>
          </div>
        {/if}
        <div
          class="rank-box"
          style={modalData.rankDetails.visuals?.primaryColor === "255,255,255,1"
            ? "background-color: rgba(0,0,0,0.1);"
            : ""}>
          <Icons
            stroke="rgba({modalData.rankDetails.visuals.textColor})"
            strokeWidth="0"
            fill="rgba({modalData.rankDetails.visuals.textColor})"
            name="total-operators"
            width="32"
            height="32" />
          <span>{translate("Amount tested casinos", lang)}:</span>
          {modalData.totalOperators}
        </div>
        <div
          class="rank-box"
          style={modalData.rankDetails.visuals?.primaryColor === "255,255,255,1"
            ? "background-color: rgba(0,0,0,0.1);"
            : ""}>
          <Icons
            stroke="rgba({modalData.rankDetails.visuals.textColor})"
            strokeWidth="0"
            fill="rgba({modalData.rankDetails.visuals.textColor})"
            name="bullseye"
            width="32"
            height="32" />
          <span>{modalData.rankDetails.name} {translate("avg value", lang)}:</span>
          {formatValue(modalData.rankDetails.score, timeMapper[selectedType], {
            lang,
          })}
        </div>
        <div
          class="rank-box"
          style={modalData.rankDetails.visuals?.primaryColor === "255,255,255,1"
            ? "background-color: rgba(0,0,0,0.1);"
            : ""}>
          <Icons
            stroke="rgba({modalData.rankDetails.visuals.textColor})"
            strokeWidth="0"
            fill="rgba({modalData.rankDetails.visuals.textColor})"
            name="median-score"
            width="32"
            height="32" />
          <span>{translate("Median value", lang)}:</span>
          {formatValue(modalData.medianRoundScore, timeMapper[selectedType], {
            lang,
          }) || translations?.["Direct"]}
        </div>
      </div>
    </div>
    <h3 style="text-align: center; margin-top: 50px;">
      {translate("Best results", lang)}: {translationsUtil[selectedType]?.[lang]}{selectedPaymentMethod
        ? " - " + selectedPaymentMethod
        : ""}
    </h3>
    <input
      class="input-search"
      placeholder={translationsUtil["Search"]?.[lang]}
      bind:value={searchText}
      on:keyup={filterList} />
    <div class="rank-list">
      {#each filteredList as operatorItem}
        <OperatorItem
          title="{translationsUtil[selectedType][lang]}{selectedPaymentMethod ? ' - ' + selectedPaymentMethod : ''}"
          timeUnit={timeMapper[selectedType]}
          {operatorItem}
          type="user-experience-modal"></OperatorItem>
      {/each}
    </div>
  </div>
</Modal>

<style>
  .operator-modal {
    padding: 12px 16px;
    margin: 0;
    padding-bottom: 180px;
  }

  @media (min-width: 992px) {
    .operator-modal {
      padding: 32px 16px;
      padding-bottom: 180px;
    }
  }
  .head {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .selected-operator-modal {
    padding: 10px;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr;
    gap: 0px 0px;
    grid-template-areas:
      "logo"
      "rank-info";
    border-radius: 8px;
    background-color: #f4f4f4;
    @media only screen and (min-width: 992px) {
      display: grid;
      grid-template-columns: 300px 1fr 1fr;
      grid-template-rows: 1fr;
      gap: 0px 0px;
      grid-auto-flow: column;
      grid-template-areas: "logo rank-info rank-info";
    }
  }

  .logo {
    width: 300px;
    grid-area: logo;
    padding-right: 10px;
    @media only screen and (min-width: 992px) {
      padding-right: 0px;
    }
    & > img {
      display: block;
      margin: auto;
      height: 60px;
    }
  }

  .rank-info {
    padding-right: 10px;
    display: grid;
    height: auto;
    align-self: center;
    font-size: 14px;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr 1fr;
    gap: 5px;
    grid-template-areas:
      "."
      "."
      ".";
    grid-area: rank-info;
    @media only screen and (min-width: 992px) {
      padding-right: 0px;
      grid-template-columns: 1fr 1fr;
      grid-template-rows: 1fr 1fr;
      grid-auto-flow: row;
      grid-template-areas:
        ". ."
        ". .";
    }
  }

  .rank-box {
    padding: 5px;
    display: flex;
    align-items: center;
    background-color: rgba(255, 255, 255, 0.15);
    border-radius: 4px;
    gap: 10px;
  }

  .rank-list {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  @media (min-width: 992px) {
    .operator-modal {
      padding-bottom: 50px;
    }
  }

  .rank-list {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  .input-search {
    background-color: #f4f4f4;
    padding: 14px 9px;
    border-radius: 8px;
    margin-bottom: 10px;
    width: 100%;
    border: none;
  }

  .empty {
    width: 32px;
    height: 16px;
  }

  .list {
    margin-top: 32px;
    list-style: disc;
    padding-left: 22px;
    gap: 0;
  }
  .list li {
    font-size: 16px;
    font-weight: 300;
    line-height: 22.96px;
    @media screen and (min-width: 992px) {
      font-size: 18px;
    }
    &::before {
      display: none;
    }
  }
  .list li span {
    font-size: 16px;
    font-weight: 700;
    line-height: 22.96px;
    @media screen and (min-width: 992px) {
      font-size: 18px;
    }
  }
</style>
