<script>
  import Group from "$lib/admin/components/Group.svelte";
  import Input from "$lib/admin/components/Input.svelte";

  // props.buttonText:
  // props.buttonTextSub:
  // props.buttonLink:
  // props.buttonType:
  export let props = null;
  export let adminView = false;
  export let type = null;
  export let dataOperator = null;
  export let dataElement = null;
</script>

{#if adminView}
  <Group colspan="4-8" addClass="form-item">
    <Input bind:value={props.buttonUrl} label="URL" placeholder="Write a URL" on:keyup />
  </Group>
  <Group colspan="4-8" addClass="form-item">
    <Input bind:value={props.buttonAnchor} label="Anchor" placeholder="Write an anchor" on:keyup />
  </Group>
{:else if !adminView && props}
  {#if type === "cta"}
    <a
      class={props.buttonType}
      href={props.buttonUrl}
      rel="nofollow noreferrer"
      target="_blank"
      data-element={dataElement}
      data-operator={dataOperator}
      data-type="button">{props.buttonAnchor}</a
    >
  {:else}
    <a class={props.buttonType} href={props.buttonUrl}>{props.buttonAnchor}</a>
  {/if}
{/if}

<style>
  a {
    display: grid;
    background-color: var(--clr-cta);
    font-size: 1rem;
    color: var(--txt-clr-alt);
    font-weight: 700;
    text-transform: uppercase;
    border: 0;
    transition: background-color 0.2s ease-out;
    text-align: center;
    border-radius: var(--bd-rad);
    padding: 0.875rem;
    line-height: calc(4 / 3);
  }
  a:hover {
    color: var(--txt-clr-alt);
    background-color: var(--clr-cta-hvr);
  }
</style>
