<script>
  import { project } from "$lib/services/store";
  import { createEventDispatcher } from "svelte";
  import Group from '$lib/admin/components/Group.svelte';
  import Input from '$lib/admin/components/Input.svelte';
  import SunEditorForm from '$lib/admin/components/content/SunEditorForm.svelte';
  import Manage from '$lib/admin/components/Manage.svelte';
  import Button from '$lib/main/components/Button.svelte';
  import ImageUpload from "$lib/admin/components/ImageUpload.svelte";
  import { ImageFolderEnum } from "$lib/common/enums/imageFolderOptions";

  export let props = null
  export let adminView = false

  function addRepeatableField() {
    if(!Array.isArray(props.content)) props.content = []
    if (props.content.length >= 4) return 
    const field = {header: "", content: "", hasBorder: true}
    props.content.push(field)
    props = props
  }

  const dispatch = createEventDispatcher();

  function removeRepeatableField(fieldIndex) {
    props.content.splice(fieldIndex, 1)
    props = props
  }
</script>

{#if adminView}
  {#if props && props.content}
    <div class="admin-columns">
      {#each props.content as item, fieldIndex}
        <div class="admin-column">
          <div class="admin-column-header">
            <span>Column {fieldIndex + 1}</span>
            <Button addClass="primary small" on:click={() => removeRepeatableField(fieldIndex)}>Remove</Button>
          </div>
          <Group addClass="form-item">
            <label class="border-toggle">
              <input type="checkbox" bind:checked={item.hasBorder} on:change={() => dispatch("keyup")}>
              Add border
            </label>
          </Group>
          <Group addClass="form-item image-editor">
            <span class="field-label">Image:</span>
            <ImageUpload
              folder={ImageFolderEnum.MEDIA}
              imageUrl={item.imageUrl}
              on:change={() => dispatch("keyup")}
              on:upload={(event) => {
                const { updatedImageUrl } = event.detail;
                item.imageUrl = updatedImageUrl;
              }}
            />
          </Group>
          <Group addClass="separator">
            <Group addClass="form-item">
              <span class="field-label">Title:</span>
              <Input bind:value={item.header} placeholder="Column title" on:keyup/>
            </Group>
            <Group addClass="form-item">
              <span class="field-label">Content:</span>
              <SunEditorForm bind:text={item.content} placeholder="Column body" on:keyup />
            </Group>
          </Group>
        </div>
      {/each}
    </div>
  {/if}
  {#if !props.content || props.content.length < 4}
    <Group addClass="form-item manage">
      <Manage>
        <Button addClass="secondary" on:click={() => addRepeatableField()}>Add column item</Button>
      </Manage>
    </Group>
  {/if}
{:else}
<div class="container">
  <div class="columns-grid">
    {#if props.content}
      {#each props.content as column}
        <div class="column" class:border={column.hasBorder}>
          {#if column.imageUrl}
            <img
              loading="lazy"
              alt=""
              class="lazyload"
              data-src={$project.data?.imageFolders?.media +
                column.imageUrl +
                "?w=600&q=80&auto=format"}
              src={$project.data?.imageFolders?.media +
                column.imageUrl +
                "?w=600&q=80&auto=format"}
            />
          {/if}
          {#if column.header}
            <span class="title">{column.header}</span>
          {/if}
          {#if column.content}
            <span class="content">{@html column.content}</span>
          {/if}
          {#if !column.header && !column.content}
            <span class="content">No columns added yet..</span>
          {/if}
        </div>
      {:else}
        <div class="column"><span class="content">No columns added yet..</span></div>
      {/each}
    {:else}
      <p>No columns added yet..</p>
    {/if}
  </div>
</div>
{/if}

<style>
  .columns-grid {
    display: grid;
    gap: 30px;
    width: 100%;
    position: relative;
    grid-template-columns: 1fr;
  }
  
  @media only screen and (min-width: 992px) {
    .columns-grid:has(> :nth-child(1):nth-last-child(2)) {
      grid-template-columns: repeat(2, 1fr);
    }

    .columns-grid:has(> :nth-child(1):nth-last-child(3)) {
      grid-template-columns: repeat(3, 1fr);
    }

    .columns-grid:has(> :nth-child(1):nth-last-child(4)) {
      grid-template-columns: repeat(4, 1fr);
    }

    .columns-grid {
      padding: 60px 20px 20px;
      gap: 10px;
    }
  }

  .column {
    width: 100%;
    padding: 60px 20px 20px;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    margin-top: 60px;
  }

  @media only screen and (min-width: 992px) {
    .column {
      margin-top: 0;
    }
  }

  .column.border {
    border: 1px solid #e3e3e3;
    border-radius: 5px;
  }

  .column img {
    width: 120px;
    height: 120px;
    object-fit: cover;
    border-radius: 5px;
    position: absolute;
    top: -60px;
    left: 50%;
    transform: translateX(-50%);
  }

  .title {
    display: block;
    margin: 20px 0 10px 0;
    font-weight: bold;
    font-size: 1.1em;
  }

  .content {
    display: block;
    margin: 0;
    line-height: 1.5;
    color: #666;
  }

  .border-toggle {
    display: flex;
    align-items: center;
    gap: 8px;
    cursor: pointer;
  }

  .border-toggle input[type="checkbox"] {
    margin: 0;
  }

  .admin-columns {
    display: grid;
    gap: 20px;
    margin-bottom: 20px;
  }

  .admin-column {
    border: 1px solid #e3e3e3;
    border-radius: 5px;
    padding: 20px;
    background: #f9f9f9;
  }

  .admin-column-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;
    padding-bottom: 10px;
    border-bottom: 1px solid #e3e3e3;
  }

  .admin-column-header span {
    font-weight: bold;
    color: #333;
  }

  :global(.admin-column-header .small) {
    padding: 5px 10px;
    font-size: 0.9em;
  }

  @media only screen and (min-width: 992px) {
    .admin-columns {
      grid-template-columns: repeat(2, 1fr);
    }
  }

  .field-label {
    display: block;
    font-size: 0.9em;
    color: #666;
    margin-bottom: 5px;
  }
</style>