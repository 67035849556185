<script>
  import { page } from "$app/stores";
  import supabase from "$lib/services/db";
  import Input from "$lib/admin/components/Input.svelte";
  import Button from "$lib/main/components/Button.svelte";
  import Group from "$lib/admin/components/Group.svelte";
  import Manage from "$lib/admin/components/Manage.svelte";
  import Icons from "$lib/main/components/Icons.svelte";
  import { projectToplists, projectAllToplists, project } from "$lib/services/store.js";

  export let props = null;
  export let adminView = false;
  let innerWidth = 0;

  let links = [];
  let loading = false;
  let toplistData = null;
  let toplistError = null;
  $: links = links.length ? links : $project.menu?.toplistMenu;

  function add() {
    links = [...links, { name: "", path: "", icon: "", toplistId: undefined }];
  }

  function remove(index) {
    links = links.filter((_, i) => i !== index);
  }

  async function save() {
    loading = true;
    const validLinks = links.filter((link) => link.name && link.path);
    const menus = {
      ...$project?.menu,
      toplistMenu: validLinks,
    };
    try {
      const { error } = await supabase
        .from("projects")
        .update({ menu: menus })
        .eq("key", $project.key);

      if (error) {
        console.error(error.message || error.error_description);
      }

      links = validLinks;
      loading = false;
    } catch (err) {
      console.error("Unexpected error:", err);
      loading = false;
    }
  }
</script>

<svelte:window bind:innerWidth />

{#if adminView}
  {#if props.linksType === 'toplistSort'}
    <Group><label>Shared toplist links list:</label></Group>
    {#if links}
      {#each links as link, index}
        <Group colspan="3-3-3-2-1" addClass="form-item narrow fs-xs">
          <Input type="text" placeholder="Anchor" bind:value={link.name} />
          <Input type="text" placeholder="URL" bind:value={link.path} />
          <Input type="text" placeholder="Icon" bind:value={link.icon} />
          <Input type="select" bind:value={link.toplistId}>
            {#if $projectToplists}
              <option value={null}>None</option>
              {#each $projectToplists as item, i}
                <option value={item.id}>{item.name}</option>
              {/each}
            {/if}
          </Input>
          <Button addClass="secondary" onlyIcon icon="trash-2" on:click={() => remove(index)} />
        </Group>
      {/each}
    {/if}

    <Group addClass="form-item manage">
      <Manage>
        <div class="wrapper">
          <Button addClass="primary" on:click={add}>Add</Button>
          <Button addClass="secondary" on:click={save}>Update Toplist Links</Button>
        </div>
      </Manage>
    </Group>
  {/if}
{:else}
  <div class="component-links list">
    <ul>
      {#each links as link}
        {@const toplistLength = $projectAllToplists.find((toplist) => toplist.id === link.toplistId)?.toplist?.length}
        <li class:active={$page.data.post?.slug === link.path.split("#")[0]}>
          <a class:icon={link.icon} href={link.path}>
            {#if link.icon}
              <Icons name={link.icon} strokeWidth="2" />
            {/if}
            <span class="title">
              {link.name}
              {toplistLength ? `(${toplistLength})` : ''}
            </span>
          </a>
        </li>
      {/each}
    </ul>
  </div>
{/if}

<style lang="postcss">
  .component-links {
    font-size: 1.125rem;
    display: block;
    overflow: hidden;
    overflow-x: auto;
    scrollbar-width: thin;
    scrollbar-color: var(--clr-pri) transparent;
    margin-bottom: 10px;
    padding-bottom: 5px;

    ul {
      list-style: none;
      padding: 0;
      margin: 0px;
      width: 100%;
      gap: 0.625rem;
      grid-auto-flow: column;

      li {
        display: flex;

        a {
          color: var(--txt-clr);
          margin: 0;
          padding: 0.625rem;
          font-size: 1rem;
          font-weight: 500;
          display: block;
          border-radius: var(--bd-rad-sm);
          cursor: pointer;
          text-decoration: none;
          gap: 0.3125rem 0.625rem;
          align-items: center;
          white-space: nowrap;

          &:hover {
            color: var(--clr-pri-txt);
            background-color: var(--clr-pri);
          }

          &.icon {
            display: flex;
          }
        }
        &.active {
          a {
            color: var(--clr-pri-txt);
            background-color: var(--clr-pri);
          }
        }
      }
    }
  }

  .wrapper {
    display: flex;
    gap: 20px;
  }
</style>
