<script lang="ts">
  import { createEventDispatcher, onMount } from "svelte";
  import type { OperatorFeedback } from "@/src/types/components/operators/OperatorFeedback";
  import Stars from "./Stars.svelte";
  import Translate from "./Translate.svelte";
  import { translations } from "$lib/data/translations";
  import { project } from "$lib/services/store";
  import Icons from "$lib/main/components/Icons.svelte";
  import { getTimeDifferenceText } from "$lib/common/helpers/date";
  import { truncateText } from "$lib/common/helpers/truncateText";

  export let feedback: OperatorFeedback;
  export let operatorName: string;

  type VariantType = "warning" | "danger" | "default" | "success";

  interface Score {
    variant: VariantType;
    label: string;
  }

  const t: Record<string, any> = translations;
  const score: Score = getScore(feedback);

  function getScore(feedback: OperatorFeedback): Score {
    if (feedback.rating > 3) {
      return {
        label: t["Good"][$project.settings.lang],
        variant: "success",
      };
    } else if (feedback.rating === 3) {
      return {
        label: t["Average"][$project.settings.lang],
        variant: "warning",
      };
    } else {
      return {
        label: t["Bad"][$project.settings.lang],
        variant: "danger",
      };
    }
  }

  let showFullText = false;
  const maxWords = 40;

  $: displayedComment = showFullText ? feedback.comment : truncateText(feedback.comment, maxWords);
  $: isExpandable = feedback.comment.split(" ").length > maxWords;

  function toggleText(): void {
    showFullText = !showFullText;
  }
</script>

<div class="embla__slide card">
  <div class="card-grid">
    <div class="flex card-head">
      <div class="card-head__title">
        {feedback.name}
      </div>
      <div class="card-head__date">
        {getTimeDifferenceText(feedback.date, $project.settings.lang)}
      </div>
    </div>
    <div class="flex card-caption">
      <div class="card-caption__label">{score.label}</div>
      <div>
        <Stars rating={feedback.rating} variant={score.variant}></Stars>
      </div>
    </div>
    <div class="card-content" style={isExpandable ? "" : "min-height: 118px;"}>
      {displayedComment}
    </div>

    {#if isExpandable}
      <div role="presentation" class="more" on:click={toggleText}>
        {#if !showFullText}
          <Translate text="Read more"></Translate>
        {:else}
          <Translate text="Show less"></Translate>
        {/if}
      </div>
    {/if}
  </div>
  <div class="flex card-footer">
    {#if feedback.sourceUrl}
      <div class="card-caption__label">
        <Translate text="Review from the site"></Translate>:
      </div>
      <div class="card-footer__source">
        <a target="_blank" href={feedback.sourceUrl}>{feedback.source}</a>
      </div>
    {/if}
  </div>
  {#if feedback.response}
    <div class="card__subfooter green">
      <div class="flex">
        <div class="card-caption__label">
          {operatorName}
          <Translate text="has responded"></Translate>
        </div>
        <span class="icon-container">
          <Icons name="fire"></Icons>
        </span>
      </div>
    </div>
  {:else}
    <div class="card__subfooter red">
      <div class="flex">
        <div class="card-caption__label">
          {operatorName}
          <Translate text="has not responded"></Translate>
        </div>
        <span class="icon-container danger">
          <Icons name="minus"></Icons>
        </span>
      </div>
    </div>
  {/if}
</div>

<style>
  .embla__slide {
    min-width: 100%;
    margin-right: 5px;
    @media only screen and (min-width: 992px) {
      min-width: 33%;
    }
  }
  .card {
    border-radius: 10px;
  }
  .card-grid {
    display: flex;
    flex-direction: column;
    gap: 5px;
    padding: 0px 10px;
    padding-top: 10px;
    background-color: #f6f6f6;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
  }
  .card-head__title {
    font-size: 16px;
    font-weight: 300;
    line-height: 20.8px;
  }
  .card-head__date {
    font-size: 14px;
    font-weight: 400;
    line-height: 18.2px;
    color: #7c7c7c;
  }
  .card-caption__label {
    font-size: 16px;
    font-weight: 500;
    line-height: 20.8px;
  }
  .card-content {
    font-size: 14px;
    font-weight: 300;
    line-height: 20.8px;
    min-height: 83px;
  }
  .card-footer {
    padding: 10px;
    background-color: #f6f6f6;
  }
  .card-footer__source {
    font-size: 16px;
    font-weight: 400;
    line-height: 20.8px;
    color: #2980b9;
  }

  .flex {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .more {
    font-size: 16px;
    font-weight: 500;
    line-height: 20.8px;
    color: #2980b9;
    padding-bottom: 10px;
    cursor: pointer;
  }
  .red {
    background: #fbf0ef;
    border-top: 1px solid #ab3326;
  }
  .green {
    background: #d5fcbe;
    border-top: 1px solid #65c82d;
  }
  .card__subfooter {
    padding: 4px 10px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
  }
  .icon-container {
    background-color: white;
    width: 16px;
    height: 16px;
    padding: 2px;
    border-radius: 10px;
    display: flex;
    align-items: center;
  }
  .danger {
    background: #ab3326;
  }
</style>
