<script lang="ts">
  import { onMount } from "svelte";
  import { writable } from "svelte/store";
  import { formatValue } from "$lib/common/helpers/formatValue";
  import { translations } from "$lib/data/translations";
  import { ExperienceEnum } from "$lib/common/enums/experience";
  import { getOrdinal } from "$lib/common/helpers/ordinalFormat";
  import type { ExperienceValues } from "$lib/common/enums/experience";
  import Icons from "$lib/main/components/Icons.svelte";
  import Skeleton from "$lib/main/components/Skeleton.svelte";
  import ModalView from "./ModalView.svelte";

  export let lang: string;
  export let loading: boolean;
  export let data: any[];
  export let type: "withdrawal" | "experience";

  let toggleModal: boolean = false;
  let modalData: any;
  let selectedType: ExperienceValues;
  let selectedPaymentMethod: string;
  let searchText: string = "";
  let filteredList: any;

  const fullWidth = writable(false);
  const translationsUtil = translations as any;
  const timeMapper: Record<ExperienceValues, string> = {
    [ExperienceEnum.EMAIL_EXPERIENCE]: "hour",
    [ExperienceEnum.LIVE_CHAT_EXPERIENCE]: "min",
    [ExperienceEnum.SPEED_EXPERIENCE]: "sec",
    [ExperienceEnum.WITHDRAWAL_EXPERIENCE]: "min",
  };
  const updateFullWidth = () => {
    fullWidth.set(window.innerWidth < 991);
  };

  function getExperienceRankColor(rank: number, totalOperators: number): string {
    const percentage = (rank / totalOperators) * 100;
    if (percentage <= 33) {
      return "#9bfca5";
    } else if (percentage <= 66) {
      return "#fbd794";
    } else {
      return "#fb9596";
    }
  }

  async function openModal(type: any, paymentMethod: string) {
    if (type === ExperienceEnum.WITHDRAWAL_EXPERIENCE) {
      modalData = data.find((wd) => wd.paymentMethod === paymentMethod);
    } else {
      const foundExperience = data.find((ed) => ed.key === type);
      modalData = foundExperience ? foundExperience.data : null;
    }

    if (modalData) {
      selectedType = type;
      selectedPaymentMethod = paymentMethod;
      filteredList = modalData.rankingList.map((item: any) => ({
        ...item,
        name: item.name,
        nameLower: item.nameLower,
        visuals: item.visuals,
      }));
      toggleModal = !toggleModal;
    } else {
      console.error("Data for the selected experience type not found");
    }
  }

  onMount(async () => {
    try {
      updateFullWidth();
      window.addEventListener("resize", updateFullWidth);
    } catch (error) {
      console.error(error);
    }
  });
</script>

<ModalView
  bind:toggleModal
  fullWidth={$fullWidth}
  {modalData}
  {selectedType}
  {selectedPaymentMethod}
  {searchText}
  {filteredList}
  {timeMapper}
  {lang}
  {translationsUtil} />

{#if loading}
  {#each Array(3) as _}
    <div class="category-content">
      <div class="title">
        <Skeleton maxWidth="170px" height="13px" customStyles="margin-top: 4px;" />
      </div>
      <div class="rank" style="padding: 0;">
        <Skeleton width="55px" height="28px" customStyles="border-radius: 4px;" />
      </div>
      <div class="rounds">
        <Skeleton maxWidth="150px" height="24px" customStyles="margin-top: 6px;" />
        <Skeleton maxWidth="150px" height="24px" customStyles="margin-top: 6px;" />
      </div>
      <div class="score">
        <Skeleton maxWidth="100px" />
      </div>
    </div>
  {/each}
{:else}
  {#each data as item}
    {#if (type === "withdrawal" && item.rankDetails) || (type === "experience" && item.data.rankDetails)}
      <div class="category-content">
        <div class="title">
          {#if type === "withdrawal"}
            {translationsUtil[ExperienceEnum.WITHDRAWAL_EXPERIENCE][lang] + " " + item.paymentMethod}
          {:else}
            {translationsUtil[item.key][lang]}
          {/if}
        </div>
        <div
          data-compare-pos={type === "withdrawal" ? item.rankDetails.rank : item.data.rankDetails.rank}
          data-element={type}
          data-operator={item.nameLower}
          data-type={type === "withdrawal" ? ExperienceEnum.WITHDRAWAL_EXPERIENCE + "_" + item.paymentMethod : item.key}
          class="rank"
          on:click={() =>
            openModal(
              type === "withdrawal" ? ExperienceEnum.WITHDRAWAL_EXPERIENCE : item.key,
              type === "withdrawal" ? item.paymentMethod : "",
            )}
          style="background-color: {getExperienceRankColor(
            type === 'withdrawal' ? item.rankDetails.rank : item.data.rankDetails.rank,
            type === 'withdrawal' ? item.totalOperators : item.data.totalOperators,
          )}"
          role="presentation">
          <span>{getOrdinal(type === "withdrawal" ? item.rankDetails.rank : item.data.rankDetails.rank, lang)}</span>
          <Icons strokeWidth="2" name="help-circle"></Icons>
        </div>
        {#if (type === "withdrawal" ? item.rankDetails.rounds : item.data.rankDetails.rounds) && (type === "withdrawal" ? item.rankDetails.rounds.length > 0 : item.data.rankDetails.rounds.length > 0)}
          <div class="rounds">
            {#each type === "withdrawal" ? item.rankDetails.rounds : item.data.rankDetails.rounds as round}
              <div class="chip">
                {`${formatValue(
                  round.result,
                  type === "withdrawal" ? timeMapper[ExperienceEnum.WITHDRAWAL_EXPERIENCE] : timeMapper[item.key],
                  { lang },
                )}  (${round.date})`}
              </div>
            {/each}
          </div>
          {#if type === "withdrawal" ? item.rankDetails.score || item.rankDetails.score === 0 : item.data.rankDetails.score || item.data.rankDetails.score === 0}
            <div class="score">
              {formatValue(
                type === "withdrawal" ? item.rankDetails.score : item.data.rankDetails.score,
                type === "withdrawal" ? timeMapper[ExperienceEnum.WITHDRAWAL_EXPERIENCE] : timeMapper[item.key],
                { lang },
              )}
              avg
            </div>
          {/if}
        {/if}
      </div>
    {/if}
  {/each}
{/if}

<style>
  .category-content {
    display: grid;
    padding: 16px 20px;
    grid-template-columns: 4fr 1fr;
    grid-template-rows: 0fr 0fr;
    grid-template-areas:
      "title ranking"
      "rounds score";
  }

  .title {
    grid-area: title;
    font-size: 18px;
    font-weight: 500;
    line-height: 23.4px;
    text-align: left;
    color: black;
    margin: 0;
  }
  .rank {
    grid-area: ranking;
    padding: 3px 5px;
    display: flex;
    justify-self: end;
    cursor: pointer;
    border-radius: 4px;
    font-size: 14px;
    font-weight: 500;
    column-gap: 4px;
    align-items: center;
  }
  .rounds {
    grid-area: rounds;
    display: flex;
    flex-wrap: wrap;
    gap: 6px;
  }
  .chip {
    font-size: 13px;
    font-weight: 300;
    line-height: 16.9px;
    text-align: left;
    border-radius: 7px;
    background-color: #ebebeb;
    padding: 4px 6px;
    width: fit-content;
    margin-top: 4px;
  }
  .score {
    grid-area: score;
    display: flex;
    min-width: 100px;
    align-items: center;
    justify-content: start;
    font-weight: 300;
    font-size: 16px;
    text-decoration: underline;
    @media screen and (min-width: 992px) {
      justify-content: end;
      text-align: right;
    }
  }
</style>
