<script lang="ts">
  import Group from "$lib/admin/components/Group.svelte";
  import Input from "$lib/admin/components/Input.svelte";
  import SunEditorForm from "$lib/admin/components/content/SunEditorForm.svelte";
  import Block from "$lib/admin/components/blocks/helpers/Block.svelte";
  import type { Operator } from "@/src/types/components/operators/Operator";
  import Collapse from "$lib/admin/components/blocks/helpers/Collapse.svelte";
  import Icons from "$lib/main/components/Icons.svelte";
  import Translate from "../helpers/Translate.svelte";
  import { getOperatorLogo } from "$lib/common/helpers/image";
  import { project, postData } from "$lib/services/store";
  import { createEventDispatcher, onMount } from "svelte";
  import RecentChanges from "../blocks/RecentChanges.svelte";

  interface IntroProps {
    showCTA: boolean;
    header: string;
    introCTAType: string;
    content: string;
    hideTOC?: boolean;
    methodology?: boolean;
    crm?: boolean;
    crmText?: string;
    recentChanges?: boolean;
    methodologyText?: string;
    recentChangesList: string[];
  }

  export let props: IntroProps;
  export let adminView: boolean = false;
  export let operator: Operator | undefined;
  export let methodology: boolean = false;
  export let methodologyText: string = "";
  export let recentChanges: boolean = false;
  export let recentChangesList: string[] = [];
  export let crm: boolean = false;
  export let crmText: string = "";

  let isTextOverflowing: boolean = false;
  let showAllText: boolean = false;
  let cardContent: HTMLElement;
  const dispatch = createEventDispatcher();

  function checkTextOverflow(): void {
    if (cardContent) {
      isTextOverflowing = cardContent?.scrollHeight > cardContent?.clientHeight;
    }
  }

  function toggleTextVisibility(): void {
    showAllText = !showAllText;
    dispatch("toggleTextVisibility", { showAllText });
  }

  onMount(() => {
    checkTextOverflow();
  });

  $: checkTextOverflow();

  $: methodology = props.methodology || methodology;
  $: methodologyText = props.methodologyText || methodologyText;
  $: recentChanges = props.recentChanges || recentChanges;
  $: recentChangesList = props.recentChangesList || recentChangesList;
  $: crm = props.crm || crm;
  $: crmText = props.crmText || crmText;

  const getColumnClass = (activeCount) => {
    if (activeCount === 1) return "g-col-md-12";
    if (activeCount === 2) return "g-col-md-6";
    return "g-col-md-4";
  };

  // Populate this array with all the features
  $: checkEnabledFeatures = [crm, methodology, recentChanges].filter(Boolean).length;
  $: columnClass = getColumnClass(checkEnabledFeatures);
</script>

{#if adminView}
  <Group colspan="4-8" addClass="form-item">
    <Input bind:value={props.header} label="Heading" placeholder="Write an H1" on:keyup />
  </Group>
  <Group addClass="form-item">
    <SunEditorForm placeholder="Introduction" bind:text={props.content} on:keyup />
  </Group>
  <Group colspan="1-11">
    <Input type="checkbox" label="Methodology" bind:checked={props.methodology} />
  </Group>
  {#if props.methodology}
    <Group addClass="form-item">
      <SunEditorForm bind:text={props.methodologyText} on:keyup />
    </Group>
  {/if}
  <Group colspan="1-11">
    <Input type="checkbox" label="Recent changes" bind:checked={props.recentChanges} />
  </Group>
  {#if props.recentChanges}
    <RecentChanges bind:listItems={props.recentChangesList} />
  {/if}
  <Group colspan="1-11">
    <Input type="checkbox" label="CRM" bind:checked={props.crm} />
  </Group>
  {#if props.crm}
    <Group colspan="4-8" addClass="form-item">
      <Input label="CRM Text" type="text" bind:value={props.crmText} />
    </Group>
  {/if}
{:else}
  <Block blockClass="block-intro" {props}>
    <div class="grid center mb-10">
      <div class={operator ? "g-col-9 flex" : "g-col-12 flex"}>
        {#if operator}
          <div
            class="image-wrapper"
            style="background-color: rgba({operator?.visuals?.[$project.key][$project.settings.market].primary_color})">
            <img alt={operator?.name_lower + " logo"} src={getOperatorLogo($project, operator?.logo)} />
          </div>
        {/if}
        <h1>{props.header ? props.header : ""}</h1>
      </div>
      {#if operator}
        <div class="g-col-3 license_label center">
          {#if operator?.rating[$project.key][$project.settings.market]["closed"]}
            <div class="closed-wrapper">
              <Translate text="Casino closed" />
            </div>
          {:else if operator?.countries.includes($project.settings.market)}
            <Translate text="English license" />
            <div class="icon-wrapper">
              <Icons name="fire" width="10px" height="10px"></Icons>
            </div>
          {:else}
            <Translate text="No English license" />
            <div class="icon-wrapper">
              <Icons name="cross" width="10px" height="10px"></Icons>
            </div>
          {/if}
        </div>
      {/if}
    </div>
    <div class="m-10">
      <div
        class="content"
        style={showAllText ? "overflow: visible;-webkit-line-clamp: unset;" : "overflow: hidden;"}
        bind:this={cardContent}>
        {@html props.content ? props.content : ""}
      </div>
    </div>
    <div role="presentation" class="more mb-10" on:click={toggleTextVisibility}>
      {#if isTextOverflowing && !showAllText}
        <Translate text="Read more"></Translate>
      {:else if showAllText && isTextOverflowing}
        <Translate text="Show less"></Translate>
      {/if}
    </div>

    <div class="grid">
      {#if crm}
        <div class={`g-col-12 g-col-sm-12 ${columnClass}`}>
          <div class="casinofeber_newsletter crm">{crmText}</div>
        </div>
      {/if}
      {#if methodology}
        <div class={`g-col-12 g-col-sm-12 ${columnClass}`}>
          <Collapse title="Our goal" icon="rocket">
            <div class="description">
              {@html methodologyText}
            </div>
          </Collapse>
        </div>
      {/if}

      {#if recentChanges}
        <div class={`g-col-12 g-col-sm-12 ${columnClass}`}>
          <Collapse title="Recent changes" icon="clock">
            <ul class="list">
              {#each recentChangesList as item}
                <li>{item}</li>
              {/each}
            </ul>
          </Collapse>
        </div>
      {/if}
    </div>
    <div class="intro-footer">
      {#if $postData?.user?.full_name}
        <p class="margin-0">
          <Translate text="Written by" />
          {$postData?.user?.full_name}
          <Icons name="verified"></Icons>
        </p>
      {/if}
      {#if $postData?.approved?.full_name}
        <p class="margin-0">
          <Translate text="Approved by" />
          {$postData?.approved.full_name}
          <Icons name="verified"></Icons>
        </p>
      {/if}
    </div>
  </Block>
{/if}

<style>
  .list {
    list-style: disc;
    padding: 0 20px 20px 20px;
    margin: 0;
    margin-left: 20px;
  }

  .description {
    padding: 0 20px 20px 20px;
  }

  .icon-wrapper {
    background-color: white;
    width: 14px;
    min-width: 14px;
    height: 14px;
    min-height: 14px;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .intro-footer {
    display: flex;
    width: 100%;
    gap: 0;
    flex-direction: column;
    margin-top: 10px;
  }
  .margin-0 {
    display: flex;
    align-items: center;
    margin: 0 !important;
    gap: 8px;
    font-size: 14px !important;
    font-weight: 700 !important;
    line-height: 18.2px !important;
    font-style: normal !important;
  }

  .license_label {
    display: flex;
    justify-self: end;
    font-size: 16px;
    font-weight: 700;
    line-height: 20.8px;
    font-style: normal;
    margin: 0;
    gap: 4px;
    align-items: center;
  }

  .center {
    align-items: center;
  }

  .image-wrapper {
    display: flex;
    width: 50px;
    height: 50px;
    background-color: white;
    align-items: center;
    justify-content: center;
    border-radius: 7px;
  }

  .image-wrapper img {
    max-width: 50px;
    max-height: 50px;
  }

  .flex {
    display: flex;
    align-items: center;
    gap: 10px;
  }

  .m-10 {
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .mb-10 {
    margin-bottom: 10px;
  }

  .crm {
    height: 54px;
    border-radius: 8px;
    background-color: #fff;
    color: #000;
    padding: 16px;
    cursor: pointer;
    font-size: 16px;
    font-weight: 700;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .closed-wrapper {
    padding: 5px 10px 5px 10px;
    gap: 4px;
    border-radius: 7px;
    background-color: #ebebeb;
    color: black;
    font-size: 16px;
    font-weight: 700;
    line-height: 20.8px;
  }

  .content {
    font-size: 16px;
    font-weight: 300;
    line-height: 20.8px;
    overflow: hidden;
    min-height: 22px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }

  .more {
    font-size: 16px;
    font-weight: 500;
    line-height: 20.8px;
    text-decoration: underline;
    cursor: pointer;
  }

  @media (min-width: 768px) {
    .intro-footer {
      flex-direction: row;
      gap: 12px;
    }
  }
</style>
