<script lang="ts">
  import emblaCarouselSvelte from "embla-carousel-svelte";
  import Icons from "$lib/main/components/Icons.svelte";

  let emblaApi: any;

  export let size: number;

  let options = { loop: false, slidesToScroll: 3 };
  let selectedIndex: number = 0;
  let isDesktop: boolean = false;
  let showControls = true;

  function onInit(event: CustomEvent) {
    emblaApi = event.detail;
    checkScreenWidth();
    emblaApi.on("select", updateSelectedIndex);
  }

  function checkScreenWidth() {
    isDesktop = window.innerWidth >= 992;
    updateControlsVisibility();
  }

  function updateSelectedIndex() {
    selectedIndex = emblaApi.selectedScrollSnap();
  }

  function updateControlsVisibility() {
    showControls = !(isDesktop && size <= 3);
  }

  window.addEventListener("resize", checkScreenWidth);

  $: adjustedSize = isDesktop ? Math.ceil(size / 3) : size;
  $: arrowsClass = showControls ? "show-arrows" : "hide-arrows";
</script>

<div class="carousel-container {arrowsClass}">
  <div class="embla" use:emblaCarouselSvelte on:emblaInit={onInit} {...options}>
    <div class="embla__container">
      <slot />
    </div>
  </div>
  {#if showControls}
    <div
      role="presentation"
      class="embla__button embla__button--prev"
      on:click={() => {
        if (selectedIndex != 0) {
          selectedIndex--;
          emblaApi.scrollPrev();
        }
      }}>
      <Icons name="arrow-left" strokeWidth="2" />
    </div>
    <div
      role="presentation"
      class="embla__button embla__button--next"
      on:click={() => {
        if (selectedIndex != adjustedSize - 1) {
          selectedIndex++;
          emblaApi.scrollNext();
        }
      }}>
      <Icons name="arrow-right" strokeWidth="2" />
    </div>
  {/if}
</div>
{#if showControls}
  <div class="embla__dots">
    {#each Array(adjustedSize).fill(1) as _, index}
      <div
        role="presentation"
        class="embla__dot {index === selectedIndex ? 'is-selected' : ''}"
        on:click={() => {
          selectedIndex = index;
          emblaApi.scrollTo(index);
        }}>
      </div>
    {/each}
  </div>
{/if}

<style>
  .carousel-container {
    position: relative;
  }

  .embla {
    overflow: hidden;
  }

  .embla__container {
    display: flex;
  }

  .embla__dots {
    margin-top: 10px;
    text-align: center;
  }

  .embla__dot {
    display: inline-block;
    width: 8px;
    height: 8px;
    background: #e0e0e0;
    border-radius: 50%;
    margin: 0 5px;
    cursor: pointer;
  }

  .embla__dot.is-selected {
    background-color: #65c82d;
  }

  .embla__button {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 50%;
    transform: translateY(-50%);
    background-color: #ebebeb;
    cursor: pointer;
    height: 32px;
    width: 34px;
    border-radius: 11px;
  }
  .embla__button--prev {
    left: -17px;
    visibility: hidden;
  }

  .embla__button--next {
    right: -17px;
    visibility: hidden;
  }

  @media only screen and (min-width: 992px) {
    .embla__button--prev,
    .embla__button--next {
      visibility: visible;
    }
  }

  .hide-arrows .embla__button {
    visibility: hidden;
  }
</style>
