<script>
  import { project } from "$lib/services/store";
  import { createEventDispatcher, onMount } from "svelte";
  import Group from "$lib/admin/components/Group.svelte";
  import ImageUpload from "$lib/admin/components/ImageUpload.svelte";
  import Input from "$lib/admin/components/Input.svelte";
  import Content from "$lib/admin/components/blocks/blocks/Content.svelte";
  import Button from "$lib/main/components/Button.svelte";
  import { getBucketLink } from "$lib/common/helpers/image";
  import { ImageFolderEnum } from "$lib/common/enums/imageFolderOptions";
  import { page } from "$app/stores";
  import { isTournamentsRoute } from "$lib/services/utils";

  export let props = null;
  export let adminView = false;

  const tournamentsRoute = $project.settings.tournamentsUrl ?? "turneringar";

  const IMG_CONTENT_FOLDER = getBucketLink() + "media/";

  const dispatch = createEventDispatcher();

  let selectedImage = 0;

  $: {
    if (props.images === undefined) {
      props.images = [{}];
    }
  }

  // This is a workaround to add data-attributes to the image links since we add them with CMS block
  let containerRef;
  onMount(() => {
    if (containerRef) {
      const links = containerRef.querySelectorAll("a");

      links.forEach((link, index) => {
        const href = link.getAttribute("href");
        if (href && href.includes("turneringar")) {
          link.setAttribute("data-element", "click_tournament");
          link.setAttribute("data-placement", "banner");
        }
      });
    }
  });
</script>

{#if adminView}
  <Group addClass="form-item" colspan="4-8">
    <Input
      label="Image block type"
      id="imageBlockType"
      type="select"
      bind:value={props.imageBlockType}
      on:change={() => dispatch("keyup")}>
      <option value="single">Image - Single</option>
      <option value="content">Image - In-content</option>
      <option value="multi">Image - Side-by-side</option>
      <option value="carousel">Image - Carousel</option>
      <option value="link">Image - Link</option>
    </Input>
  </Group>
  {#if props.images.length}
    {#each props.images as image, idx}
      {#if !((props.imageBlockType === "single" || props.imageBlockType === "content") && idx > 0) && !((props.imageBlockType === "multi" || props.imageBlockType === "carousel") && idx != selectedImage)}
        <Group colspan="2" addClass="form-item image-editor">
          <ImageUpload
            imageUrl={image.url}
            on:change={() => dispatch("keyup")}
            on:upload={(event) => {
              const { updatedImageUrl } = event.detail;
              image.url = updatedImageUrl;
            }}
            folder={ImageFolderEnum.MEDIA} />
          {#if image.url}
            <Group colspan="1" addClass="small-input">
              <Group>
                <Input label="Alt text" placeholder="Alternative text" id="alt-text" bind:value={image.alt} on:keyup />
              </Group>
              {#if props.imageBlockType === "link"}
                <Group>
                  <Input
                    label="Link URL"
                    placeholder="Enter URL"
                    id="link-url"
                    bind:value={image.linkUrl}
                    on:keyup={() => dispatch("keyup")} />
                </Group>
                <Group colspan="1-11">
                  <Input
                    label="Open in new window"
                    type="checkbox"
                    bind:checked={image.openNewWindow}
                    on:change={() => dispatch("keyup")} />
                </Group>
              {/if}
              {#if props.imageBlockType === "multi" || props.imageBlockType === "carousel"}
                {#if props.images[0].singleCaption}
                  <Group>
                    <Input
                      label="Image caption"
                      placeholder="Image caption"
                      id="caption"
                      bind:value={props.images[0].caption}
                      on:keyup={() => dispatch("keyup")} />
                  </Group>
                {:else}
                  <Group>
                    <Input
                      label="Image caption"
                      placeholder="Image caption"
                      id="caption"
                      bind:value={image.caption}
                      on:keyup={() => dispatch("keyup")} />
                  </Group>
                {/if}
                <Group colspan="1-11">
                  <Input
                    label="Single caption"
                    type="checkbox"
                    bind:checked={props.images[0].singleCaption}
                    on:change={() => dispatch("keyup")} />
                </Group>
              {:else}
                <Group>
                  <Input
                    label="Image caption"
                    placeholder="Image caption"
                    id="caption"
                    bind:value={image.caption}
                    on:change={() => dispatch("keyup")} />
                </Group>
              {/if}
              <Group colspan="1-11">
                <Input
                  label="Image border"
                  type="checkbox"
                  bind:checked={image.border}
                  on:change={() => dispatch("keyup")} />
              </Group>
              {#if image.border}
                <Group colspan="1-11">
                  <Input
                    label="Caption outside border"
                    type="checkbox"
                    bind:checked={image.captionOutside}
                    on:change={() => dispatch("keyup")} />
                </Group>
              {/if}
              <Group colspan="1-11">
                <Input
                  label="Hide on mobile"
                  type="checkbox"
                  bind:checked={image.hideOnMobile}
                  on:change={() => dispatch("keyup")} />
              </Group>
              <Group colspan="1-11">
                <Input
                  label="Hide on desktop"
                  type="checkbox"
                  bind:checked={image.hideOnDesktop}
                  on:change={() => dispatch("keyup")} />
              </Group>
              {#if props.imageBlockType === "content"}
                <Group>
                  <Input
                    label="Image float"
                    id="imageFloat"
                    type="select"
                    bind:value={props.images[0].float}
                    on:change={() => dispatch("keyup")}>
                    <option value="no-float">None</option>
                    <option value="left">Left</option>
                    <option value="right">Right</option>
                  </Input>
                  <Group>
                    <Input
                      label="Image width"
                      id="imageWidth"
                      type="number"
                      bind:value={props.images[0].width}
                      on:change={() => dispatch("keyup")}></Input>
                  </Group>
                </Group>
              {/if}
            </Group>
          {/if}
        </Group>
        {#if props.imageBlockType === "content"}
          <Content {adminView} {props} on:keyup />
        {/if}
        {#if props.imageBlockType === "multi" || props.imageBlockType === "carousel"}
          <Group colspan="1-auto" addClass="form-item narrow">
            <Group colspan="1-auto-auto" addClass="narrow">
              <div class="multi-meta">
                Showing image {selectedImage + 1} of {props.images.length} images
              </div>
              <Button
                on:click={() => {
                  if (selectedImage > 0) selectedImage -= 1;
                  else selectedImage = props.images.length - 1;
                }}>‹</Button>
              <Button
                on:click={() => {
                  selectedImage += 1;
                  if (selectedImage >= props.images.length) selectedImage = 0;
                }}>›</Button>
            </Group>
            <Group addClass="inline narrow">
              {#if props.images.length > 1}
                <Button
                  addClass="secondary"
                  title="Remove this image"
                  onlyIcon
                  icon="trash-2"
                  on:click={() => {
                    props.images = props.images.filter((_, i) => i !== idx);
                    if (selectedImage > 0) {
                      selectedImage -= 1;
                    }
                    dispatch("keyup");
                  }}>Remove</Button>
              {/if}
              {#if !props.images.length || props.imageBlockType === "multi" || props.imageBlockType === "carousel"}
                <Button
                  addClass="primary"
                  title="Add an image"
                  onlyIcon
                  icon="plus-square"
                  on:click={() => {
                    props.images.push({});
                    dispatch("keyup");
                  }}>Add Image</Button>
              {/if}
            </Group>
          </Group>
        {/if}
      {/if}
    {/each}
  {/if}
{:else}
  <div
    style="background: {props.bgColor};"
    class:block-padding={props.imageBlockType !== "link"}
    class:block-link={props.imageBlockType == "link"}
    class:hide-mobile={props.images?.[0]?.hideOnMobile}
    class:hide-desktop={props.images?.[0]?.hideOnDesktop}
    bind:this={containerRef}>
    <div class="container">
      <div class="block-image">
        <div class:grid={!isTournamentsRoute($page.url.pathname, tournamentsRoute)}>
          <div class="g-col-12 g-start-1 g-col-md-10 g-start-md-2">
            {#if props.images?.length}
              {#if props.imageBlockType === "single" || !props.imageBlockType || props.imageBlockType === "link"}
                {#if props.images[0].border}
                  <div class="image-holder border" class:caption-outside={props.images[0].captionOutside}>
                    {#if props.imageBlockType === "link" && props.images[0].linkUrl}
                      <a href={props.images[0].linkUrl} target={props.images[0].openNewWindow ? "_blank" : null}>
                        <img
                          class={"in-content lazyload"}
                          loading="lazy"
                          alt={props.images[0].alt}
                          data-src={IMG_CONTENT_FOLDER + props.images[0].url + "?w=1000&q=80&auto=format"}
                          src={IMG_CONTENT_FOLDER + props.images[0].url + "?w=1000&q=80&auto=format"}
                          on:error={(ev) =>
                            (ev.target.src = `${$project.data?.imageFolders?.assets}/casinofeber-banner.png?w=600&h=400&q=80&auto=format`)} />
                      </a>
                    {:else}
                      <img
                        class={"in-content lazyload"}
                        loading="lazy"
                        alt={props.images[0].alt}
                        data-src={IMG_CONTENT_FOLDER + props.images[0].url + "?w=1000&q=80&auto=format"}
                        src={IMG_CONTENT_FOLDER + props.images[0].url + "?w=1000&q=80&auto=format"}
                        on:error={(ev) =>
                          (ev.target.src = `${$project.data?.imageFolders?.assets}/casinofeber-banner.png?w=600&h=400&q=80&auto=format`)} />
                    {/if}
                    {#if props.images[0].caption && !props.images[0].captionOutside}
                      <p class="img-caption">{@html props.images[0].caption}</p>
                    {/if}
                  </div>
                  {#if props.images[0].caption && props.images[0].captionOutside}
                    <p class="img-caption outside">{@html props.images[0].caption}</p>
                  {/if}
                {:else}
                  <div
                    class:hide-mobile={props.images[0].hideOnMobile}
                    class:hide-desktop={props.images[0].hideOnDesktop}>
                    {#if props.imageBlockType === "link" && props.images[0].linkUrl}
                      <a href={props.images[0].linkUrl} target={props.images[0].openNewWindow ? "_blank" : null}>
                        <img
                          class={"in-content lazyload"}
                          loading="lazy"
                          alt={props.images[0].alt}
                          data-src={IMG_CONTENT_FOLDER + props.images[0].url + "?w=1000&q=80&auto=format"}
                          src={IMG_CONTENT_FOLDER + props.images[0].url + "?w=1000&q=80&auto=format"}
                          on:error={(ev) =>
                            (ev.target.src = `${$project.data?.imageFolders?.assets}/casinofeber-banner.png?w=600&h=400&q=80&auto=format`)} />
                      </a>
                    {:else}
                      <img
                        class={"in-content lazyload"}
                        loading="lazy"
                        alt={props.images[0].alt}
                        data-src={IMG_CONTENT_FOLDER + props.images[0].url + "?w=1000&q=80&auto=format"}
                        src={IMG_CONTENT_FOLDER + props.images[0].url + "?w=1000&q=80&auto=format"}
                        on:error={(ev) =>
                          (ev.target.src = `${$project.data?.imageFolders?.assets}/casinofeber-banner.png?w=600&h=400&q=80&auto=format`)} />
                    {/if}

                    {#if props.images[0].caption}
                      <p class="img-caption outside">{@html props.images[0].caption}</p>
                    {/if}
                  </div>
                {/if}
              {:else if props.imageBlockType === "content"}
                <div class="image-content-wrapper">
                  <div
                    style="--image-width: {props.images[0].width}%;"
                    class={"image-holder" + (props.images[0].float ? " " + props.images[0].float : "")}
                    class:dark-mode={isTournamentsRoute($page.url.pathname, tournamentsRoute)}
                    class:hide-mobile={props.images[0].hideOnMobile}
                    class:hide-desktop={props.images[0].hideOnDesktop}>
                    <img
                      class={"in-content lazyload"}
                      class:border={props.images[0].border}
                      loading="lazy"
                      alt={props.images[0].alt}
                      data-src={IMG_CONTENT_FOLDER + props.images[0].url}
                      src={IMG_CONTENT_FOLDER + props.images[0].url} />
                    {#if props.images[0].caption}
                      <p class="img-caption">{@html props.images[0].caption}</p>
                    {/if}
                  </div>
                  <div
                    style="--text-width: {100 - props.images[0].width}%;"
                    class="text-holder"
                    class:tournament-page={isTournamentsRoute($page.url.pathname, tournamentsRoute)}>
                    {#if props.content}
                      {@html props.content}
                    {/if}
                  </div>
                </div>
              {:else if props.imageBlockType === "multi" || props.imageBlockType === "carousel"}
                <div class={props.imageBlockType}>
                  {#each props.images as image, i}
                    <div
                      class="image-holder"
                      class:hide-mobile={image.hideOnMobile}
                      class:hide-desktop={image.hideOnDesktop}>
                      <img
                        class={"in-content lazyload"}
                        class:border={image.border}
                        loading="lazy"
                        alt={image.alt}
                        data-src={IMG_CONTENT_FOLDER + image.url + "?w=600&q=80&auto=format"}
                        src={IMG_CONTENT_FOLDER + image.url + "?w=600&q=80&auto=format"} />
                      {#if image.caption && !props.images[0].singleCaption}
                        <p class="img-caption">{@html image.caption}</p>
                      {/if}
                    </div>
                  {/each}
                </div>
                {#if props.images[0].caption && props.images[0].singleCaption}
                  <p class="img-caption single">{@html props.images[0].caption}</p>
                {/if}
              {/if}
            {/if}
          </div>
        </div>
      </div>
    </div>
  </div>
{/if}

<style>
  .block-link {
    & img {
      margin-bottom: 5px;
    }
  }
  .block-padding {
    padding: 12px 0;
  }
  .block-image {
    .image-content-wrapper {
      display: flex;
      flex-flow: column;
      align-items: center;

      @media (min-width: 768px) {
        flex-flow: row;
        flex-direction: row-reverse;
      }
    }
    .image-holder {
      width: 100%;
      @media (min-width: 768px) {
        width: var(--image-width);
      }
    }
    .text-holder {
      width: 100%;
      @media (min-width: 768px) {
        width: var(--text-width);
      }
    }
  }

  .dark-mode.image-holder.left,
  .dark-mode.image-holder.right {
    width: 100%;
    margin: 0 auto;

    @media (min-width: 768px) {
      max-width: min(450px, 60%);
    }
  }
  @container window (min-width: 450px) {
    .dark-mode.image-holder.left {
      float: left;
      margin: 0;
      @media (min-width: 768px) {
        margin-right: 1rem;
      }
    }
    .dark-mode.image-holder.right {
      float: right;
      margin: 0;

      @media (min-width: 768px) {
        margin-left: 1rem;
      }
    }
  }
  :global(.tournament-page a) {
    text-decoration: underline;
    text-decoration-color: #cc3129 !important;
    text-underline-offset: 8px;
    text-decoration-thickness: 3px;
    color: white;

    &:hover {
      color: lightgray;
    }
  }
  :global(.tournament-page p) {
    margin: 12px 0;
  }

  .hide-mobile {
    @media only screen and (max-width: 990px) {
      display: none !important;
    }
  }

  .hide-desktop {
    @media only screen and (min-width: 992px) {
      display: none !important;
    }
  }
</style>
