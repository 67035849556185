<script lang="ts">
  import { createEventDispatcher } from "svelte";
  import { project, orgOperators } from "$lib/services/store";
  import type { Operator } from "@/src/types/components/operators/Operator";
  import Group from "$lib/admin/components/Group.svelte";
  import Input from "$lib/admin/components/Input.svelte";
  import Button from "$lib/main/components/Button.svelte";
  import SunEditorForm from "../../content/SunEditorForm.svelte";
  import { getBucketLink } from "$lib/common/helpers/image";
  import Terms from "../helpers/Terms.svelte";

  export let props: any;
  export let adminView: boolean;

  let operators = $orgOperators as Operator[];
  const dispatch = createEventDispatcher();

  function addListItem() {
    props.listItems = [
      ...props.listItems,
      {
        operator: null,
        description: "",
      },
    ];
  }

  function removeListItem() {
    if (props.listItems.length > 0) {
      props.listItems = props.listItems.slice(0, -1);
    }
  }

  $: {
    if (props.listItems === undefined) {
      props.listItems = [];
    }
  }

  let draggedIndex: any = null;

  function handleDragStart(index: any) {
    draggedIndex = index;
  }

  function handleDragOver(event: any, index: any) {
    event.preventDefault();
    if (draggedIndex !== index) {
      const updatedList = [...props.listItems];
      const [movedItem] = updatedList.splice(draggedIndex, 1);
      updatedList.splice(index, 0, movedItem);

      props.listItems = updatedList;
      draggedIndex = index;
    }
  }

  function handleDragEnd() {
    draggedIndex = null;
  }
</script>

{#if adminView}
  <div class="parent-draggable-item">
    {#if props.listItems?.length > 0}
      {#each props.listItems as item, index (index)}
        <div
          role="listitem"
          draggable="true"
          on:dragstart={() => handleDragStart(index)}
          on:dragover={(e) => handleDragOver(e, index)}
          on:dragend={handleDragEnd}
          class="draggable-item"
        >
          <Group colspan="4-8">
            <Input
              label={"Operator" + " " + (index + 1)}
              type="select"
              addClass="h4"
              bind:value={item.operatorId}
              on:change={() => dispatch("keyup")}
            >
              <option value="">~not set~</option>
              {#each operators as operator}
                <option value={operator.id}>{operator.name ?? "Couldn't find metadata"}</option>
              {/each}
            </Input>
            <Input label="Title" type="text" required bind:value={item.title} on:keyup />
          </Group>
          <Group>
            <SunEditorForm bind:text={item.content} on:keyup />
          </Group>
        </div>
      {/each}
    {/if}
  </div>
  <div class="action-box">
    {#if props.listItems?.length > 1}
      <Button addClass="secondary" icon="minus-square" on:click={() => removeListItem()}>Remove last item</Button>
    {/if}
    {#if props.listItems?.length < 4}
      <Button addClass="primary" icon="plus-square" on:click={() => addListItem()}>Add item</Button>
    {/if}
  </div>
{:else}
  <div class="container cards-container">
    {#each props.listItems as item, index (index)}
      {#if item.operatorId}
        {#each operators as operator (operator.id)}
          {#if operator.id === item.operatorId}
            <div class="card">
              <a
                class="upper-content"
                href={`https://www.casinofeber.se/spela/${operator?.name_lower}`}
                data-name={operator?.name}
                data-type="title"
                data-alias={`${index + 1} Highlight`}
                data-element="top-banner"
                data-operator={operator?.name_lower}
                data-toplist-pos={index + 1}
                target="_blank"
              >
                <div class="card-content">
                  <div
                    style={`background-color: rgba(${
                      operator?.visuals?.[$project.key][$project.settings.market].primary_color
                    }); background-image: url("${getBucketLink()}operators/${operator?.logo?.[$project.key]}"); background-size: 90%;
                    background-repeat: no-repeat;
                    background-position: center;`}
                    title={operator?.name}
                    class="logo"
                    data-name={operator?.name}
                    data-alias={`${index + 1} Highlight`}
                    data-element="top-banner"
                    data-operator={operator?.name_lower}
                    data-toplist-pos={index + 1}
                  ></div>
                  <div class="text-container">
                    <span class="title">
                      {#if item.title}
                        {item.title}
                      {/if}
                    </span>
                    <span class="text">
                      {#if item.content.length}
                        {@html item.content}
                      {/if}
                    </span>
                  </div>
                </div>
              </a>
              <div class="terms">
                <Terms {operator} />
              </div>
            </div>
          {/if}
        {/each}
      {/if}
    {/each}
  </div>
{/if}

<style>
  .cards-container {
    margin: 10px auto;
    gap: 14px;
    display: flex;
    flex-direction: column;
    @media only screen and (min-width: 992px) {
      flex-direction: row;
      justify-content: center;
    }
  }
  .card {
    flex: 1;
    overflow: hidden;
    width: 100%;

    @media only screen and (min-width: 992px) {
      max-width: 270px;
    }
  }
  .upper-content {
    display: flex;
    flex-direction: column;
  }
  .card-content {
    display: flex;
    align-items: center;
    color: #000;
    font-family: Gilroy;
    font-size: 14px;
    font-weight: 500;
    line-height: 20.4px;
    text-align: left;
    overflow: hidden;
    @media (min-width: 992px) {
      align-items: flex-start;
    }
  }
  .logo {
    min-width: 130px;
    border-radius: 6px 0 0 0;
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
    @media only screen and (min-width: 992px) {
      min-width: 95px;
    }
  }
  .text-container {
    background-color: #f4f4f4;
    border-radius: 0 6px 0 0;
    padding: 8px 8px 8px 0;
    height: 80px;
    width: 100%;
  }
  .title {
    display: block;
    height: 18px;
    overflow: hidden;
    margin: 0 0 5px 5px;
    font-weight: 500;
    & > a {
      color: inherit;
    }
  }
  .text {
    height: 100%;
    display: block;
    max-height: 40px;
    overflow: hidden;
  }
  .action-box {
    display: flex;
    gap: 10px;
  }
  .terms {
    height: 26px;
    padding: 4px;
    border: 1px solid #e3e3e3;
    border-radius: 0 0 5px 5px;
    width: 100%;
    font-size: 10px !important;
    font-weight: 300;
    text-align: center;
    overflow-y: scroll; /* Show vertical scrollbar */

    &::-webkit-scrollbar {
      width: 4px;
    }

    &::-webkit-scrollbar-track {
      background: #e0e0e0;
      border-radius: 6px;
      border: 6px;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 6px;
      background: #37505c;
    }

    &::-webkit-scrollbar-thumb:hover {
      background: #263942;
    }
  }
  .parent-draggable-item {
    display: flex;
    flex-flow: column;
    gap: 20px;
  }
  .draggable-item {
    display: flex;
    flex-flow: column;
    gap: 15px;
  }
</style>
